import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import AppBar from '@mui/material/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@mui/material/Drawer'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { ExitToApp, PlaylistAddCheck } from '@mui/icons-material'
import FilterListIcon from '@mui/icons-material/FilterList'
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import PersonIcon from '@mui/icons-material/Person'
import SyncIcon from '@mui/icons-material/Sync'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MailIcon from '@mui/icons-material/Mail'
import NotificationsIcon from '@mui/icons-material/Notifications'
import clsx from 'clsx'
import React, { FunctionComponent, useEffect, useState, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import './app.css'
import { CUSTOMERS, MANUFACTURING, ORDERS, PROOFS, SHIPPING } from './constants'
import { apiService } from './service/api'
import { useAppDispatch } from './store'
import { getRouterState } from './store/app/selectors'
import { fetchUsers } from './store/app/thunk'
import { auth, EPermissions, setLogin, setLogout } from './store/auth'
import { getAuthState, getIsAuthenticated } from './store/auth/selectors'
import { fetchUser, signin, signout } from './store/auth/thunks'
import { setFiltersDrawerOpen } from './store/ui'
import { getUiState } from './store/ui/selectors'
import Badge from '@material-ui/core/Badge'
import SearchBar from './components/search/Searchbar'
import { version } from './version'
import {
  Box,
  IconButton,
  Toolbar,
  Typography as Typography2,
} from '@mui/material'

interface IListItemLinkProps {
  icon: any
  title: string
  to: string
}

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    },
    title: {
      flexGrow: 1,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-start',
    },
    content: {
      flexGrow: 1,
      // padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: 0,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    button: {
      marginTop: theme.spacing(1),
      width: '100%',
    },
    grow: {
      flexGrow: 1,
    },
    footer: {
      marginBottom: theme.spacing(4),
    },
  })
)

const ListItemLink = ({ icon, title, to }: IListItemLinkProps) => {
  const {
    location: { pathname },
  } = useSelector(getRouterState)

  return (
    <ListItem button component={NavLink} to={to} selected={pathname === to}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  )
}

const DrawerContents = (props: { onClose: () => void }) => {
  const dispatch = useAppDispatch()
  const { user } = useSelector(getAuthState)
  const isAuthenticated = useSelector(getIsAuthenticated)

  return (
    <>
      <Toolbar sx={{ backgroundColor: '#1976d2' }}>
        <Typography2 variant="h6" noWrap sx={{ color: 'white' }}>
          Corkscrew
        </Typography2>
      </Toolbar>
      <ListItemLink title="Convert" icon={<SyncIcon />} to={'/convert'} />

      <Divider />

      {user?.permissions?.includes(EPermissions.OrderManage) ? (
        <ListItemLink title="Customers" icon={<PersonIcon />} to={CUSTOMERS} />
      ) : null}

      {user?.permissions?.includes(EPermissions.OrderManage) ? (
        <ListItemLink
          title="Orders"
          icon={<FormatListNumberedIcon />}
          to={ORDERS}
        />
      ) : null}
      {user?.permissions?.includes(EPermissions.ProofManage) ? (
        <ListItemLink title="Proofs" icon={<PlaylistAddCheck />} to={PROOFS} />
      ) : null}
      {user?.permissions?.includes(EPermissions.ManufacturingManage) ? (
        <ListItemLink
          title="Manufacturing"
          icon={<FormatColorFillIcon />}
          to={MANUFACTURING}
        />
      ) : null}
      {user?.permissions?.includes(EPermissions.ShippingManage) ? (
        <ListItemLink
          title="Shipping"
          icon={<LocalShippingIcon />}
          to={SHIPPING}
        />
      ) : null}
      {/* <ListItemLink title="Account" icon={<AccountCircle />} to={ACCOUNT} /> */}

      <Divider />
      {isAuthenticated ? (
        <ListItem
          button
          onClick={() => {
            props.onClose()
            dispatch(signout())
          }}
        >
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      ) : (
        <ListItem
          button
          onClick={() => {
            props.onClose()
            dispatch(signin())
          }}
        >
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Login" />
        </ListItem>
      )}
      <Divider />
      <Box style={{ textAlign: 'center' }}>
        <Typography style={{ padding: 8 }} variant="caption">
          v{version}
        </Typography>
      </Box>
    </>
  )
}

interface IAppProps {
  children: React.ReactNode
}

const App: FunctionComponent<IAppProps> = ({ children }: IAppProps) => {
  const classes = useStyles()
  const dispatch = useAppDispatch()

  // const [open, setOpen] = useState(false)
  const { accessToken } = useSelector(getAuthState)
  const [isDrawOpen, setDrawState] = useState<boolean>(true)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { isFiltersDrawerOpen } = useSelector(getUiState)
  const isAuthenticated = useSelector(getIsAuthenticated)
  // const [searchTerm, setSearchTerm] = useState('')

  const {
    location: { pathname },
  } = useSelector(getRouterState)

  // useEffect(() => {
  //   if (isDrawOpen) {
  //     setDrawState(false)
  //   }
  // }, [pathname])

  useEffect(() => {
    dispatch(fetchUsers())
    if (isAuthenticated && accessToken) {
      apiService.setApiKey(accessToken)
    }
  }, [isAuthenticated, accessToken])

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null

    console.log('isAuth', isAuthenticated)

    if (isAuthenticated) {
      interval = setInterval(() => {
        refreshToken()
      }, 60 * 20 * 1000)
    }

    return () => {
      if (interval) {
        console.log('auth clearing interval')
        clearInterval(interval)
      }
    }
  }, [isAuthenticated])

  // const getUsers = async () => {
  //   try {
  //     const designersAction = await dispatch(fetchUsers(''))
  //     unwrapResult(designersAction)
  //   } catch (error) {
  //     Bugsnag.notify(error)
  //   }
  // }

  const refreshToken = () => {
    auth.checkSession({ prompt: 'none' }, (err, authResult) => {
      if (err || !authResult) {
        return dispatch(setLogout())
      }

      apiService
        .setApiKey(authResult.accessToken)
        .then(() => {
          return dispatch(
            setLogin({
              accessToken: authResult.accessToken,
              tokenId: authResult.idToken,
              expiresAt: new Date().getTime() + authResult.expiresIn * 1000,
              user: {
                name: authResult.idTokenPayload.name,
                email: authResult.idTokenPayload.email,
                picture: authResult.idTokenPayload.picture,
              },
            })
          )
        })
        .then(() => dispatch(fetchUser(authResult.accessToken)))
        .catch(() => dispatch(setLogout()))
    })
  }

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleDrawerOpen = () => {
    dispatch(setFiltersDrawerOpen())
  }

  // const handleDrawerClose = () => {
  //   setOpen(false)
  // }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: isAuthenticated
            ? { sm: `calc(100% - ${drawerWidth}px)` }
            : '100%',
          ml: { sm: `${drawerWidth}px` },
          boxShadow: 'none',
        }}
      >
        <Toolbar sx={{ maxHeight: 48 }}>
          {isAuthenticated ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawState(!isDrawOpen)}
              sx={{ display: { xs: 'initial', md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          ) : null}

          {isAuthenticated ? null : (
            <Typography2 variant="h6" noWrap sx={{ color: 'white' }}>
              Corkscrew
            </Typography2>
          )}

          {isAuthenticated ? (
            <Fragment>
              <SearchBar includeLineItems={true} />
              <div className={classes.grow} />
            </Fragment>
          ) : null}

          {isAuthenticated ? (
            <>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                className={clsx(isFiltersDrawerOpen && classes.hide)}
                disabled={pathname === '/'}
              >
                <FilterListIcon />
              </IconButton>
              <IconButton
                disabled
                aria-label="show 0 new mails"
                color="inherit"
              >
                <Badge badgeContent={0} color="secondary">
                  <MailIcon />
                </Badge>
              </IconButton>
              <IconButton
                disabled
                aria-label="show 0 new notifications"
                color="inherit"
              >
                <Badge badgeContent={0} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleMenuClick}
              >
                <AccountCircle />
              </IconButton>
            </>
          ) : null}

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {isAuthenticated ? (
              <MenuItem
                color="inherit"
                onClick={() => {
                  handleMenuClose()
                  setDrawState(false)
                  dispatch(setLogout())
                }}
              >
                Logout
              </MenuItem>
            ) : (
              <MenuItem
                color="inherit"
                onClick={() => {
                  handleMenuClose()
                  setDrawState(false)
                  dispatch(signin())
                }}
              >
                Login
              </MenuItem>
            )}
          </Menu>
        </Toolbar>
      </AppBar>
      {/* <div className={classes.drawerHeader} /> */}
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          anchor="left"
          open={isDrawOpen}
          onClose={() => setDrawState(false)}
          variant="temporary"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          <DrawerContents onClose={() => setDrawState(false)} />
        </Drawer>
        {isAuthenticated ? (
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
                borderRight: 'none',
                backgroundColor: '#EBEBEB',
              },
            }}
            open
          >
            <DrawerContents onClose={() => setDrawState(false)} />
          </Drawer>
        ) : null}
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Box sx={{ marginTop: '48px' }}>{children}</Box>
      </Box>
      <footer className={classes.footer}></footer>

      {/* <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <FiltersDrawer onDrawerClose={handleDrawerClose} />
      </Drawer> */}
    </Box>
  )
}

export default App
